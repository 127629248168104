import React from "react";
import { Column } from "../components/columns";
import {
  Italic,
  Small,
  SubTitle,
  CardTitle,
  Paragraph,
} from "../components/text";
import { Button } from "./button/button";

import "./contact_item.sass";

export default ({ description, emails, title, phone }) => (
  <Column width="is-one-third">
    <div className="contact-item">
      <div className="employee-info">
        <CardTitle center marginBottom>
          {title}
        </CardTitle>
        <Paragraph center marginBottom marginTop>
          {description}
        </Paragraph>
        {phone && phone.length > 0 && (
          <a href={`tel:${phone}`} className="link">
            <Button title={`Call (${phone})`} marginTopLarge expand />
          </a>
        )}
        {emails &&
          emails.length > 0 &&
          emails.map((email) => (
            <a
              href={`mailto:${email.email}`}
              className="link"
              key={email.email}
            >
              <Button title={email.title} marginTop expand />
            </a>
          ))}
      </div>
    </div>
  </Column>
);
// <img src={ image } alt={ title } />
