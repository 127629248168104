import React from "react";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { Title } from "../components/text";
import ContactItem from "../components/contact_item";
import { Columns } from "../components/columns";

const getEmails = (post) => {
  const emails = [];
  for (let index = 1; index <= 3; index++) {
    const email = post[`email${index}`];
    const title = post[`email${index}_title`];
    if (email && title) {
      emails.push({
        email,
        title,
      });
    }
  }
  return emails;
};

export const ContactPageTemplate = ({
  title,
  image,
  posts,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  // console.warn(posts)
  // console.log('postings_title', postings_title)
  return (
    <Page noBottomPadding>
      <Helmet title="Wesmans | Contact" />
      <FullWidthImage image={image} height="50vh" parallax={50} dim={0.4}>
        <Title bright>{title}</Title>
      </FullWidthImage>
      <Section diffBg>
        <Columns multiLine center>
          {posts &&
            posts.map((post) => (
              <ContactItem
                key={post.frontmatter.phone + post.frontmatter.title}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                phone={post.frontmatter.phone}
                emails={getEmails(post.frontmatter)}
                date={post.frontmatter.date}
              />
            ))}
        </Columns>
      </Section>
    </Page>
  );
};
// <Section>
//   <CareerSubmit />
// </Section>

export default ({ data }) => {
  // console.log(data)
  const { markdownRemark: post } = data;
  let { edges: posts } = data.allMarkdownRemark;
  posts = posts.filter(
    (post) => post.node.frontmatter.templateKey === "contact-posting"
  );
  posts = posts.map(({ node: post }) => post);

  // console.log(posts)

  return (
    <ContactPageTemplate
      title={post.frontmatter.title}
      image={post.frontmatter.image}
      posts={posts}
    />
  );
};

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            phone
            email1
            email1_title
            email2
            email2_title
            email3
            email3_title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
